import { Col, Form, Row } from 'antd';
import { TextInput } from 'components/UI/FormItems';

export const AddressForm = (): JSX.Element => {
  return (
    <Form layout="vertical">
      <Row gutter={[32, 16]}>
        <Col span={12}>
          <TextInput disabled fieldName="addressFormatted" label="Full Address" />
        </Col>
        <Col span={12}>
          <TextInput disabled fieldName="street" label="Street" />
        </Col>
        <Col span={12}>
          <TextInput disabled fieldName="city" label="City" />
        </Col>
        <Col span={12}>
          <TextInput disabled fieldName="state" label="State" />
        </Col>
        <Col span={12}>
          <TextInput disabled fieldName="country" label="Country" />
        </Col>
        <Col span={12}>
          <TextInput disabled fieldName="zipCode" label="Zip Code" />
        </Col>
      </Row>
    </Form>
  );
};
