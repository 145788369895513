import { Col, Form, Row } from 'antd';
import { TextInput } from 'components/UI/FormItems';
import React from 'react';

export const CreateLegalEntityForm = (): JSX.Element => {
  return (
    <Form layout="vertical">
      <Row gutter={[32, 16]}>
        <Col span={12}>
          <TextInput fieldName="name" label="Name" />
        </Col>
        <Col span={12}>
          <TextInput fieldName="description" label="Description" />
        </Col>
        <Col span={12}>
          <TextInput fieldName="dataAreaId" label="Data Area Id" />
        </Col>
      </Row>
    </Form>
  );
};
