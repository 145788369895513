import * as yup from 'yup';
export interface LegalEntity {
  createdByFullName: string;
  address: LegalEntityAddress;
  createdByUserId: string;
  createdDateTime: string;
  dataAreaId: string;
  description: string;
  documentType: string;
  id: string;
  isActive: boolean;
  isDeleted: boolean;
  modifiedByFullName: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  name: string;
  partitionKey: string;
  partitionKeyDescription: string;
  logoUrl: string;
}

export interface EditLegalEntity {
  name: string;
  description: string;
  dataAreaId: string;
  addressFormatted?: string;
  city: string;
  country: string;
  county?: string;
  locationId?: string;
  state: string;
  street: string;
  zipCode: string;
}
export interface DeleteLegalEntity {
  id: string;
}

export interface CreateLegalEntityPayload {
  name: string;
  description: string;
  dataAreaId: string;
  addressFormatted?: string;
  city: string;
  country: string;
  county?: string;
  locationId?: string;
  state: string;
  street: string;
  zipCode: string;
}

export interface LegalEntityParams {
  includeDeletedData?: boolean;
  includeInactiveData?: boolean;
  returnDeletedDataOnly?: boolean;
  returnInactiveDataOnly?: boolean;
  orderByField?: string;
  orderByDirection?: string;
  legalEntityNameContains?: string;
  dataAreaIdContains?: string;
}

interface LegalEntityAddress {
  addressFormatted: string;
  city: string;
  country: string;
  county: string;
  locationId: string;
  state: string;
  street: string;
  zipCode: string;
}

export const legalEntityPayload: yup.SchemaOf<EditLegalEntity> = yup.object({
  name: yup.string().label('name').required(),
  description: yup.string().label('description').required(),
  dataAreaId: yup.string().label('dataAreaId').required(),
  addressFormatted: yup.string().label('full address').notRequired(),
  city: yup.string().label('city').required(),
  country: yup.string().label('country').required(),
  county: yup.string().label('county').notRequired(),
  locationId: yup.string().label('location id').notRequired(),
  state: yup.string().label('state').required(),
  street: yup.string().label('street').required(),
  zipCode: yup.string().label('zip code').required()
});
