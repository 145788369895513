import { Card, Col, message, Modal, Row, Typography } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoverLegalEntityMutation } from 'redux/services/maggieCadabby/maggieCadabbyApi';
import { InlineStylesModel } from '../../models/InlineStylesModel';

const styles: InlineStylesModel = {
  card: {
    borderRadius: '4px',
    cursor: 'pointer',
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '96%',
    height: '175px',
    marginBottom: 15
  },
  cardLink: {
    display: 'flex',
    justifyContent: 'center'
  },
  icons: {},
  meta: {
    width: '100%',
    marginBottom: 5
  }
};

export const LegalEntityCardCard = ({ legalEntity }: any): JSX.Element => {
  /* ******************** Hooks ******************** */

  const [recoverLegalEntity] = useRecoverLegalEntityMutation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  /* ******************** Functions ******************** */
  const handleEdit = (): void => {
    if (legalEntity.isDeleted) {
      setIsModalOpen(!isModalOpen);
    } else {
      navigate(`legalEntities/${legalEntity.id}`);
    }
  };

  const handleOk = async (): Promise<void> => {
    try {
      await recoverLegalEntity({ legalEntityId: legalEntity.id as string }).unwrap();
      message.success('Legal Entity successfuly recovered');
    } catch (error) {
      console.log(error);
      message.error('Legal Entity failed to be recovered!');
    }
  };

  const handleExit = () => {
    setIsModalOpen(false);
  };

  /* ******************** Render ******************** */
  return (
    <Card hoverable style={styles.card} onClick={handleEdit}>
      <Meta style={styles.meta} title={legalEntity.name} description={legalEntity.description} />
      <Typography.Text>{`Data Area Id: ${legalEntity.dataAreaId}`}</Typography.Text>
      <Modal open={isModalOpen} onOk={handleOk} closable onCancel={handleExit}>
        <Row gutter={[32, 16]} align="middle" justify="center">
          <Col span={24}>
            <Typography>Do you wish to recover this Legal Entity?</Typography>
          </Col>
          <Col span={24}></Col>
        </Row>
      </Modal>
    </Card>
  );
};
