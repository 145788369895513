import { Space } from 'antd';
import { HomeControls } from 'components/organisms/HomeControls';
import { RecyclingBin } from 'components/organisms/RecyclingBin';
import React from 'react';

export const RecycleBinPage = (): JSX.Element => {
  return (
    <Space size={16} direction="vertical">
      <HomeControls />
      <RecyclingBin />
    </Space>
  );
};
