import React, { useEffect } from 'react';
import { setLegalEntityNameContains } from 'redux/services/maggieCadabby/legalEntitiesParams';
import { useAppDispatch } from 'redux/store';
import { useDebouncedCallback } from 'use-debounce';
import { FancyInput } from '../Inputs';

export const LegalEntityNameFilter = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const dispatch = useAppDispatch();

  /* ******************** Functions ******************** */
  const handleChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    dispatch(setLegalEntityNameContains(value || undefined));
  }, 500);

  // Clears state on unmount
  useEffect(() => {
    return (): void => {
      dispatch(setLegalEntityNameContains(undefined));
    };
  }, [dispatch]);

  return <FancyInput placeholder="Legal Entity Name" onChange={handleChange} />;
};
