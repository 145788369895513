import { List } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { LegalEntityCardCard } from 'components/molecules/LegalEntityCard';
import React from 'react';
import { useGetLegalEntitiesQuery } from 'redux/services/maggieCadabby/maggieCadabbyApi';

export const RecyclingBin = (): JSX.Element => {
  const { data: legalEntityData, isLoading, isFetching } = useGetLegalEntitiesQuery({ returnDeletedDataOnly: true });

  /* ******************** Render ******************** */
  return (
    <List
      grid={{ gutter: 16, column: 4 }}
      loading={{
        indicator: <BlockLoader direction="loader loader--slideUp" />,
        size: 'large',
        spinning: isLoading || isFetching
      }}
      dataSource={legalEntityData?.data}
      renderItem={(item): JSX.Element => <LegalEntityCardCard legalEntity={item} />}
    />
  );
};
