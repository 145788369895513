import { Col } from 'antd';
import { DataAreaIdFilter } from 'components/atoms/Filters/DataAreaIdFilter';
import { LegalEntityNameFilter } from 'components/atoms/Filters/LegalEntityNameFilter';
import { Stack } from 'components/UI';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import React from 'react';
import { useLocation } from 'react-router-dom';

export const HomeControls: React.FC = () => {
  const { pathname } = useLocation();

  const isRecyclingBinPage = pathname.includes('recycling-bin');

  return (
    <Stack flexDirection="row" maxWidth={1920}>
      <Col style={{ paddingRight: 35 }}>
        <Stack justifyContent="space-between">
          <AntPageTitle text="Legal Entities" />
        </Stack>
      </Col>
      {!isRecyclingBinPage && (
        <Col style={{ paddingRight: 35 }}>
          <Stack justifyContent="start">
            <LegalEntityNameFilter />
            <DataAreaIdFilter />
          </Stack>
        </Col>
      )}
    </Stack>
  );
};
