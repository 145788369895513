import { List } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { LegalEntityCardCard } from 'components/molecules/LegalEntityCard';
import { useSelector } from 'react-redux';
import { useGetLegalEntitiesQuery } from 'redux/services/maggieCadabby/maggieCadabbyApi';
import { ReduxState } from 'redux/store';

export const BusinessList = (): JSX.Element => {
  const { maggieCadabbyParams } = useSelector((state: ReduxState) => state);
  const { data: legalEntityData, isLoading, isFetching } = useGetLegalEntitiesQuery(maggieCadabbyParams);

  /* ******************** Render ******************** */
  return (
    <List
      grid={{ gutter: 6, column: 5 }}
      loading={{
        indicator: <BlockLoader direction="loader loader--slideUp" />,
        size: 'large',
        spinning: isLoading || isFetching
      }}
      dataSource={legalEntityData?.data}
      renderItem={(item): JSX.Element => <LegalEntityCardCard legalEntity={item} />}
    />
  );
};
