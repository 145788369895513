import { Col, Collapse, Row } from 'antd';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
const { Panel } = Collapse;

export const ReleaseNotesPage = (): JSX.Element => {
  const { version } = useParams();

  return (
    <Fragment>
      <Row justify="space-between" style={{ padding: 10, background: 'rgb(255, 234, 193)', borderRadius: 5 }}>
        <Col>
          <AntPageTitle text={'Release Notes'} />
        </Col>
      </Row>
      <Collapse defaultActiveKey={['4.2']}>
        <Panel header="Version 4.2" key="4.2">
          <div>General</div>
          <ol>
            <li>Users may now set the logo for the company.</li>
            <li>Users may now set the address for the company.</li>
            <li>Release notes have been added to the website.</li>
            <li>The menu will no longer erroneously collapse to the mobile view on large view ports.</li>
            <li>T5.	The favicon has been updated to the latest MDSi favicon.</li>
          </ol>
        </Panel>
      </Collapse>
    </Fragment>
  );
};
