import { EditFilled, SaveOutlined } from '@ant-design/icons';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Avatar, Col, message, Modal, Row, Spin, Upload, UploadProps } from 'antd';
import { AntButton } from 'components/UI/AntButton';
import { InlineStylesModel } from 'models/InlineStylesModel';
import React, { useCallback, useState } from 'react';
import Cropper, { Area, Point } from 'react-easy-crop';
import { useParams } from 'react-router-dom';
import { useGetLegalEntityQuery, useUpsertLegalEntityLogoMutation } from 'redux/services/maggieCadabby/maggieCadabbyApi';
import { getCroppedImg } from 'utils/canvasUtils';
import defaultImage from '../../assets/companyDefault.png';
import mdsiDefault from '../../assets/defaultMdsiLogo.png';

const styles: InlineStylesModel = {
  cropContainer: {
    height: '60vh'
  },
  controls: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    width: '100%',
    transform: 'translateX(-50%)',
    height: 60,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    paddingLeft: 10
  },
  title: {
    position: 'absolute',
    top: 0,
    left: '50%',
    width: '100%',
    transform: 'translateX(-50%)',
    height: 60,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    zIndex: 1,
    justifyContent: 'center'
  }
};

export const LogoUploadLegalEntity = () => {
  const { id: entityId } = useParams();

  const [updateLegalEntityLogo, { isLoading: isDeleting }] = useUpsertLegalEntityLogoMutation();
  const { data: legalEntityData, isLoading, isFetching } = useGetLegalEntityQuery(entityId || skipToken);

  const [showModal, setShowModal] = useState(false);
  const [imageSrc, setImageSrc] = React.useState('');
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(2);
  const [hover, setHover] = useState(false);

  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  function readFile(file: any) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  const handleCroppedImageUpload = useCallback(async () => {
    if (!entityId) return message.error('No legal entity found, please refresh the page');
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      croppedImage?.toBlob((blob: BlobPart | any) => {
        const fileCropped = new File([blob], `logo${new Date().toISOString()}.jpeg`, { type: 'image/jpeg' });
        const formData = new FormData();
        formData.append('logo.jpeg', fileCropped);

        updateLegalEntityLogo({ entityId, payload: formData });
      }, 'image/jpeg');
      message.success('Logo successfully changed');
    } catch (error) {
      message.error('Logo could not be changed at this time');
      console.error(error);
    }
  }, [entityId, imageSrc, croppedAreaPixels, updateLegalEntityLogo]);

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onOk = () => {
    try {
      setImageSrc('');
      setShowModal(false);
      handleCroppedImageUpload();
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = () => {
    if (!entityId) return message.error('No legal entity found, please refresh the page');

    const emptyLogo = new FormData();
    emptyLogo.append('logo.jpeg', '');
    updateLegalEntityLogo({ entityId, payload: emptyLogo });
  };

  const handleCloseModal = () => {
    setImageSrc('');
    setShowModal(false);
  };

  const props: UploadProps = {
    name: 'file',
    async onChange(info: any) {
      setShowModal(true);
      if (info.file) {
        const file = info.file.originFileObj;
        let imageDataUrl = await readFile(file);

        setImageSrc(imageDataUrl as string);
      }
    }
  };

  return (
    <div style={{ paddingTop: 32 }}>
      {imageSrc ? (
        <React.Fragment>
          <Modal width={800} open={showModal} onCancel={handleCloseModal} onOk={onOk}>
            <div style={styles.cropContainer}>
              <Cropper cropShape="round" showGrid={false} image={imageSrc} crop={crop} zoom={zoom} aspect={1} onCropChange={setCrop} onCropComplete={onCropComplete} onZoomChange={setZoom} />
            </div>
            <div style={styles.controls}>
              <input
                type="range"
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e) => {
                  setZoom(e.target.value as any);
                }}
                className="zoom-range"
              />
            </div>
          </Modal>
        </React.Fragment>
      ) : (
        <>
          <Row align="middle">
            <Upload {...props}>
              <Col style={{ borderRadius: 50 }} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                <Avatar
                  icon={(isLoading || isFetching) && <Spin spinning></Spin>}
                  style={{ cursor: 'pointer', filter: hover ? 'brightness(50%)' : '', transition: '0.25s ease-in-out', position: 'relative' }}
                  size={100}
                  alt=""
                  src={!legalEntityData?.logoUrl && legalEntityData?.dataAreaId.toLocaleLowerCase() === 'mdsi' ? mdsiDefault : legalEntityData?.logoUrl ? legalEntityData.logoUrl : defaultImage}
                />
                {hover && (
                  <Avatar
                    icon={<EditFilled />}
                    style={{ cursor: 'pointer', filter: hover ? 'brightness(60%)' : '', transition: '0.25s ease-in-out', position: 'absolute', left: 0, opacity: 0.65 }}
                    size={100}
                    alt=""
                  />
                )}
              </Col>
            </Upload>
            <Col>
              <AntButton style={{ marginLeft: 16 }} disabled={!legalEntityData?.logoUrl} onClick={handleDelete} loading={isDeleting} danger type="ghost" icon={<SaveOutlined />}>
                Delete Current Picture
              </AntButton>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};
