import { About } from 'pages/About';
import { CreateLegalEntityPage } from 'pages/CreateLegalEntity';
import { EditLayout } from 'pages/EditLayout';
import { EditLegalEntityPage } from 'pages/EditLegalEntity';
import { EditProfilePage } from 'pages/EditProfile';
import { Forbidden } from 'pages/Forbidden';
import { HomePage } from 'pages/Home';
import { RecycleBinPage } from 'pages/RecycleBin';
import { ReleaseNotesPage } from 'pages/ReleaseNotesPage';
import { Route, Routes } from 'react-router-dom';

export const MainRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<About />} />
      <Route path="/release-notes/:version" element={<ReleaseNotesPage />} />
      <Route path="/unauthorized" element={<Forbidden />} />
      <Route path="/profile" element={<EditProfilePage />} />
      <Route path="/legalEntities/create-legal-entity/" element={<CreateLegalEntityPage />} />
      <Route path="/legalEntities/recycling-bin/" element={<RecycleBinPage />} />
      <Route path="/legalEntities/:id" element={<EditLayout />}>
        <Route index element={<EditLegalEntityPage />} />
      </Route>
    </Routes>
  );
};
